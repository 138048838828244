import { useState } from "react"
import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { RichTextRenderer, SearchForm } from "../components/_common"


import { Layout, SectionContainer, SectionTitle } from "../components/_layout"

const getPageData = graphql`
    query {
        glosarioData: allContentfulContenidoGlosario {
            edges {
                node {
                    contenido {
                        raw
                    }
                }
            }
        }
    }
`

export interface IGlosarioContent {
  contenido: string
}

const Glosario: React.FC = () => {
  const { glosarioData } = useStaticQuery(getPageData)
  const glosario: IGlosarioContent[] = getSectionData(glosarioData)
  const [searchText, setSearchText] = useState("")

  const handleSearch = (text) => {
    setSearchText(text.toLowerCase())
  }

  return (
    <Layout
      withNav
      seoTitle="Glosario"
      seoDescription="Glosario"
    >
      <SectionContainer sectionID={ "glosario" }>
        <SectionTitle title={ "Glosario" } />
        <SearchForm onFormSubmit={ handleSearch } />
        <RichTextRenderer withSearch content={ glosario[0].contenido } { ...{ searchText } } />
      </SectionContainer>
    </Layout>
  )
}

export default Glosario

const getSectionData = (arr: any) =>
  arr.edges.map(({ node }: any) => ({
    contenido: node.contenido.raw
  }))
